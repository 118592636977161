@import "../../styles/variables";

.drawer {
  $animation-duration: 300ms;

  .drawer-backdrop {
    z-index: 15;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(3px);
    transition: all $animation-duration;

    visibility: hidden;
    opacity: 0;

    pointer-events: none;

    &.open {
      visibility: visible;
      opacity: 1;

      pointer-events: auto;
    }
  }

  .drawer-container {
    position: fixed;
    z-index: 20;

    background-color: $background-color;
    box-shadow: .2rem 1rem 3rem rgba(0, 0, 0, 0.6);

    padding: 1rem;
    padding-top: 3rem;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .drawer-close {
      position: absolute;
      top: 1rem;
      right: 1.2rem;
      font-size: 1.3rem;
    }

    .drawer.bottom & {
      transform: translateY(-100%);
    }

    transition: all $animation-duration;
    visibility: hidden;

    &.open {
      visibility: visible;
    }
  }

  &.left .drawer-container {
    top: 0;
    left: 0;
    bottom: 0;
    width: 20rem;
    max-width: 70vw;
    transform: translateX(-100%);

    border-right: $soft-border;

    &.open {
      transform: translateX(0);
    }
  }

  &.bottom .drawer-container {
    left: 0;
    bottom: 0;
    right: 0;
    max-height: 40vw;
    transform: translateY(100%);

    border-top: $soft-border;

    padding-bottom: 3rem;

    &.open {
      transform: translateY(0);
    }
  }
}
