@import '../../../../styles/variables';

.epk-section {
  padding: 1rem 0;

  @media (max-width: $max-breakpoint-m) {
    padding: 1rem;
  }

  & > .title {
    margin-top: 0.5rem;
    margin-bottom: 0.8rem;
  }
}
