.epk-member {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1rem;

  .epk-member_image {
    height: 30vh;
    aspect-ratio: .75;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .epk-member_info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .epk-member_content {
      margin-top: 1rem;
    }
  }
}
