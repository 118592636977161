.language-selector {
  position: relative;

  .selector-position {
    height: 0;
    position: relative;

    .selector {
      z-index: 10;
      position: absolute;
      top: 0.8rem;
      left: 50%;
      transform: translateX(-50%);

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.3rem;
    }
  }
}