.epk-music {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2rem;

  padding-top: 3rem;

  .epk-music_album {
    width: 10rem;
  }
}
