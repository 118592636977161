@import "../../styles/variables";

.epk {
  width: 100vw;
  min-height: 100vh;

  @media (min-width: $min-breakpoint-l) and (max-width: $max-breakpoint-l) {
    width: 900px;
    margin: 0 auto;
  }
  @media (min-width: $min-breakpoint-xl) {
    width: 1100px;
    margin: 0 auto;
  }
}
