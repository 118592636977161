@import '../../styles/variables';

.text {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > .prefix {
    margin-right: 0.5rem;
    width: 1rem;
  }

  & > .suffix {
    margin-left: 0.5rem;
    width: 1rem;
  }

  &.italic {
    font-style: italic;
  }

  &.regular {
    font-size: 1rem;
  }

  &.h1 {
    font-size: 2rem;
    font-weight: bold;
  }

  &.h2 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  &.h3 {
    font-size: 1.3rem;
    font-weight: bold;
  }

  &.p {
    font-size: 1rem;
    margin-top: 0.3rem;
    margin-bottom: 0.6rem;

    @media (min-width: $min-breakpoint-m) {
      font-size: 1.2rem;
    }
  }

  &.p2 {
    font-size: .8rem;
    margin-top: 0.2rem;
    margin-bottom: 0.4rem;

    @media (min-width: $min-breakpoint-m) {
      font-size: 1rem;
    }
  }
}
