@import "../../styles/variables";

.footer {
  &.desktop {
    @media (max-width: $max-breakpoint-m) {
      display: none;
    }

    padding: 4rem;
    padding-bottom: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
  }

  &.mobile {
    @media (min-width: $min-breakpoint-l) {
      display: none;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 5rem;

    .button-container {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      z-index: 10;

      background-color: $background-color;
      border-top: $soft-border;

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
