@import "../../styles/variables";

.contact {
  display: flex;

  @media (max-width: $max-breakpoint-m) {
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
  }
  @media (min-width: $min-breakpoint-l) {
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
  }
}
