@import '../../styles/variables';

.responsive-menu {

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
  }

  .responsive-menu_language-selector {
    margin-top: 2rem;
  }
}