@import "./styles/variables";

body {
  margin: 0;
  padding: 0;
  border: none;
  font-family:
    'Futura Round',
    'Roboto',
    -apple-system,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $background-color;
  color: rgba(255, 255, 255, .9);
}

html {
  @media (max-width: $max-breakpoint-s) {
    font-size: 120%;
  }

  @media (min-width: $min-breakpoint-m) and (max-width: $max-breakpoint-m) {
    font-size: 100%;
  }

  @media (min-width: $min-breakpoint-l) and (max-width: $max-breakpoint-l) {
    font-size: 120%;
  }

  @media (min-width: $min-breakpoint-xl) {
    font-size: 100%;
  }
}
