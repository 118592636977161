@import "../../styles/variables";

.main-page {
  width: 100vw;
  @media (max-width: $max-breakpoint-m) {
    min-height: 100vh;
    padding-bottom: 5rem;
  }
  @media (min-width: $min-breakpoint-l) {
    height: 100vh;
  }

  display: flex;
  flex-direction: column;
}
