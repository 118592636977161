@import "../../styles/variables";

.header {
  padding: 1rem 1.5rem;
  padding-bottom: 4rem;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (max-width: $max-breakpoint-m) {
    justify-content: center;
    position: relative;
    padding-bottom: 2rem;
  }
  @media (min-width: $min-breakpoint-l) {
    justify-content: space-between;
  }

  $sides-width: 10rem;

  & > .menu-desktop {
    @media (max-width: $max-breakpoint-m) {
      display: none;
    }

    width: $sides-width;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  & > .menu-mobile {
    @media (min-width: $min-breakpoint-l) {
      display: none;
    }

    position: absolute;
    left: 1rem;
    top: 1rem;
  }

  & > .header-paumei {
    margin-top: 2rem;
  }

  & > .language-desktop {
    @media (max-width: $max-breakpoint-m) {
      display: none;
    }

    width: $sides-width;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
