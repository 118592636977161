.paumei {
  font-family: 'Bohemian Soul', serif;
  font-weight: 200;
  font-size: 2.5rem;
  letter-spacing: .3rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .3rem;

  &.clickable {
    cursor: pointer;

    &:hover {
      color: white;
      text-decoration: underline;
      text-underline-offset: .5rem;
    }
  }
}
