@import "../../styles/variables";

.active-album {
  flex: 1;

  display: flex;
  justify-content: center;

  @media (max-width: $max-breakpoint-m) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 2rem;
  }
  @media (min-width: $min-breakpoint-l) {
    flex-direction: row;
    align-items: stretch;
  }

  $sides-min-width: 5rem;

  margin-bottom: 2rem;

  .links {
    flex: 1;
    min-width: $sides-min-width;

    & > * {
      width: 100%;
      height: 100%;
    }
  }

  .album-column {
    @media (max-width: $max-breakpoint-m) {
      align-self: stretch;
      aspect-ratio: 1;
      max-height: 60vh;
    }
    @media (min-width: $min-breakpoint-l) {
      flex: 2;
    }

    position: relative;

    .album-container {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: 1;

      display: flex;
      align-items: stretch;
      justify-content: stretch;
    }
  }

  .right {
    flex: 1;
    min-width: $sides-min-width;
  }
}
