.album-link {
  width: 8rem;
  height: 3rem;

  cursor: pointer;

  transition: transform 150ms;

  &:hover {
    transform: scale(1.3);

    img {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    opacity: .8;
    transition: opacity 150ms;
  }
}
