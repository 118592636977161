.album {
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  .album-image-container {
    flex: 1;

    margin: 1rem;

    max-width: 40rem;
    max-height: 40rem;

    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: .2rem 1rem 3rem black;

    position: relative;

    .album-title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -3rem;

      font-weight: bold;
      font-size: 1.4rem;

      color: rgba(255, 255, 255, .7);
    }

    img {
      width: 100%;
      height: 100%;

      border-radius: 1rem;
    }
  }
}
