
$futura-font-name: 'Futura Round';

@font-face {
  font-family: $futura-font-name;
  src:
    local("Futura Round"),
    url("../public/fonts/Futura-Round-Font/Futura Round.ttf") format("truetype"),
    url("../public/fonts/Futura-Round-Font/Futura Round.woff") format("woff")
}

@font-face {
  font-family: $futura-font-name;
  font-weight: bold;
  src:
    local("Futura Round Bold"),
    url("../public/fonts/Futura-Round-Font/Futura Round Bold.ttf") format("truetype"),
    url("../public/fonts/Futura-Round-Font/Futura Round Bold.woff") format("woff")
}

@font-face {
  font-family: 'Fanlste';
  src:
    local('Fanlste'),
    url('../public/fonts/Fanlste/fanlste.ttf') format('truetype'),
    url('../public/fonts/Fanlste/fanlste.woff') format('woff')
}

@font-face {
  font-family: 'Bohemian Soul';
  src:
    local('Bohemian Soul'),
    url('../public/fonts/Bohemian-Soul/Bohemian-Soul.ttf') format('truetype'),
    url('../public/fonts/Bohemian-Soul/Bohemian-Soul.woff') format('woff')
}
