.epk-members {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }
}
