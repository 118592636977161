.active-album-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
}
