@import "../../../../styles/variables";

.epk-header {
  padding-top: 3rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  position: relative;

  @media (max-width: $max-breakpoint-m) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .epk-header_language-selector {
    position: absolute;
    top: 1rem;
    right: 0;
  }
}
