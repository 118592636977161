.button {
  cursor: pointer;

  transition: color 150ms;
  letter-spacing: .2rem;
  text-transform: uppercase;

  &:hover {
    color: white;
    text-decoration: underline;
    text-underline-offset: .5rem;
  }
}
