$max-breakpoint-s: 767px;
$min-breakpoint-m: 768px;
$max-breakpoint-m: 1023px;
$min-breakpoint-l: 1024px;
$max-breakpoint-l: 1439px;
$min-breakpoint-xl: 1440px;

$background-color: rgb(23, 23, 23);

$soft-border: 1px solid rgb(100, 100, 100);
